<template>
  <div class="container">   
    <section class="top">
       <h2>QUERO VENDER</h2>
    <br />
      <div class="row">
        <div class="cols-12">
          <img
            :src="require('@/assets/images/quero-vender.png')"
            alt="quero vender"
            title="Quero vender"
            width="100%"
            height="100%"
          />
        </div>
      </div>
    </section>
    <!-- <a href="https://api.whatsapp.com/send?phone=5521982931484" target="_black">
      <img
        class="fixed"
        :src="require('@/assets/images/whatsapp.png')"
        alt="whatsapp"
        title="whatsapp"
        width="70"
      />
    </a> -->
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.top {
  padding: 200px 20px 0;
}

.fixed {
  position: fixed;
  top: 80%;
  left: 80%;
  z-index: 1000;

  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}
</style>
